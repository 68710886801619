import React from 'react';
import ForecastsTableRow from "../ForecastsTableRow/ForecastsTableRow";

function ForecastsTable(props) {
    return (
        <table className="table table-striped">
            <thead>
            <tr>
                <th className="date">{getFormattedDay(props.forecasts[0].datetime)}</th>
                <th>Height</th>
                <th>Wind Speed</th>
                <th>Wind Direction</th>
            </tr>
            </thead>
            <tbody>
            {props.forecasts.map((forecast, index) => {
                return (
                    <ForecastsTableRow key={index} forecast={forecast}/>
                );
            })}
            </tbody>
        </table>
    );
}

function getFormattedDay(dateString) {
    let date = new Date(dateString);
    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const options = { month: 'numeric', day: 'numeric' };
    const userLocale = navigator.language || navigator.userLanguage;

    return days[date.getDay()] + " " + date.toLocaleDateString(userLocale, options);
}

export default ForecastsTable;
