import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/home";
import Privacy from "./pages/privacy";
import Imprint from "./pages/imprint";
import './App.css';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spots: [],
            searchMode: null,
            location: null,
            mapDisplayed: false,
            searchResultMessage: null,
            isApp: navigator.userAgent.indexOf("kooksurffinder-app") > -1
        };
    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path="privacy" element={<Privacy/>}/>
                    <Route path="imprint" element={<Imprint/>}/>
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;
