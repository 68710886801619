import React, {Component} from 'react';
import {Map, Marker, Popup} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import SpotCard from "../SpotCard/SpotCard";

export class SpotsMap extends Component {
    state = {
        map: null,
        selectedSpot: null,          // Shows the InfoWindow to the selected place upon a marker
        currentMapBounds: null,
        firstRender: true,
        donationLayerCount: 0
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.spots !== this.props.spots) {
            this.fitMapBoundsToSpots();
        }
    }

    fitMapBoundsToSpots(map = null) {
        if (this.props.searchMode === 'boundingBox')
            return;

        map = map ?? this.state.map;
        const boundingBox = this.getBoundingBox(this.props.searchMode === 'boundingBox' ? this.props.spots : this.props.spots.concat([this.props.location]), true, 0.1);

        map.fitBounds(boundingBox);
    }

    onPopupClose = props => {
        this.setState({
            selectedSpot: null,
        });

        const spotsMap = document.getElementById('spots-map');

        if (spotsMap !== null)
            spotsMap.classList.remove('popup-open');
    };

    onPopupOpen(e) {
        const popup = e.target;
        const map = popup._map;
        const pan = [0, 0];
        const point = map.project(popup._lngLat);
        const popupSize = {
            width: popup._container.clientWidth,
            height: popup._container.clientHeight,
        };
        const popupRect = popup._container.getBoundingClientRect();
        const mapRect = map._container.getBoundingClientRect();

        // Center point horizontally to make the popup visible, if needed
        const popupRelativeLeft = popupRect.x - mapRect.x;
        const popupClippedHorizontally = popupRelativeLeft < 0 || (mapRect.width - popupRelativeLeft - popupSize.width) < 0;
        if (popupClippedHorizontally) {
            if (popupRelativeLeft < 0) {
                pan[0] = point.x - (mapRect.width + popupRect.width) / 2;
                // right side overflow
            } else if (mapRect.width - popupRelativeLeft - popupSize.width < 0) {
                pan[0] = point.x - (mapRect.width - popupRect.width) / 2;
            }
        }

        // Move point to the top to make the popup visible, if needed
        const popupRelativeTop = popupRect.y - mapRect.y;
        const popupClippedVertically = popupRelativeTop < 0 || (mapRect.height - popupRelativeTop - popupSize.height) < 0;
        if (popupClippedVertically) {
            pan[1] = point.y - ((mapRect.height - popupRect.height) / 2);
        }

        if (pan !== [0, 0]) {
            map.panBy(pan);
        }

        // scroll .mapboxgl-popup-content to top
        const popupContent = document.querySelector('.mapboxgl-popup-content');
        popupContent.scrollTop = 0;

        const spotsMap = document.getElementById('spots-map');
        spotsMap.classList.add('popup-open');
    }


    static defaultProps = {
        zoom: 11
    };

    getBoundingBox = (spots, asArray = false, padding = 0) => {
        let minLat = 90;
        let maxLat = -90;
        let minLng = 180;
        let maxLng = -180;

        if (spots.length === 1) {
            minLat = parseFloat(spots[0]?.latitude ?? 0) - 0.1;
            maxLat = parseFloat(spots[0]?.latitude ?? 0) + 0.1;
            minLng = parseFloat(spots[0]?.longitude ?? 0) - 0.1;
            maxLng = parseFloat(spots[0]?.longitude ?? 0) + 0.1;
        } else {
            spots.forEach(spot => {
                minLat = Math.min(minLat, spot?.latitude ?? 0);
                maxLat = Math.max(maxLat, spot?.latitude ?? 0);
                minLng = Math.min(minLng, spot?.longitude ?? 0);
                maxLng = Math.max(maxLng, spot?.longitude ?? 0);
            });
        }

        if (asArray) {
            return [minLng - padding, minLat - padding, maxLng + padding, maxLat + padding];
        }

        return {
            minLat: minLat - padding, maxLat: maxLat + padding, minLng: minLng - padding, maxLng: maxLng + padding
        }
    }

    getZoomByBoundingBox = (boundingBox) => {
        const minLat = boundingBox.minLat;
        const maxLat = boundingBox.maxLat;
        const minLng = boundingBox.minLng;
        const maxLng = boundingBox.maxLng;
        const latDiff = maxLat - minLat;
        const lngDiff = maxLng - minLng;
        const latZoom = Math.round(Math.log(360 / latDiff) / Math.LN2);
        const lngZoom = Math.round(Math.log(360 / lngDiff) / Math.LN2);
        return Math.min(latZoom, lngZoom, 12) - 1;
    }

    getZoom = (spots) => {
        const boundingBox = this.getBoundingBox(spots);
        return this.getZoomByBoundingBox(boundingBox);
    }

    getCenter = function (spots) {
        let centerLat = 0;
        let centerLng = 0;

        if (spots.length === 1) {
            if (spots[0] !== undefined && spots[0] != null) {
                centerLat = spots[0].latitude ?? 0;
                centerLng = spots[0].longitude ?? 0;
            } else {
                centerLat = 0;
                centerLng = 0;
            }
        } else {
            const boundingBox = this.getBoundingBox(spots);

            centerLat = (boundingBox.maxLat + boundingBox.minLat) / 2;
            centerLng = (boundingBox.maxLng + boundingBox.minLng) / 2;
        }

        return {lat: centerLat, lng: centerLng};
    }
    onFilterChangeButtonClick = () => {
        document.getElementById('form').classList.remove('hidden');

        setTimeout(() => {
            document.getElementById('form').classList.remove('faded');
        }, 50);
    }

    onSearchThisAreaButtonClick = () => {
        const currentMapBounds = this.state.currentMapBounds;
        const boundingBoxInput = document.getElementById('bounding-box');
        boundingBoxInput.value = `${currentMapBounds.lat_min},${currentMapBounds.lng_min},${currentMapBounds.lat_max},${currentMapBounds.lng_max}`;

        this.props.onSearchThisAreaButtonClick();

        // add disabled attribute to the search-this-area button
        const searchThisAreaButton = document.querySelector('.search-this-area');
        searchThisAreaButton.setAttribute('disabled', 'disabled');

        console.log("Search this area", currentMapBounds);
    }

    deg2rad = (deg) => {
        return deg * (Math.PI / 180)
    }

    render() {
        return (<div>
            <div id="spots-map" className={"spots-map full-screen"}>
                <Map
                    mapboxAccessToken={'pk.eyJ1IjoiYm9laG1pMTk4OCIsImEiOiJjbHQwa2kycHQwdTlhMmtvNnh6dmIzZ29vIn0.MZECD0625YIA8T0kT5ctQw'}
                    mapLib={import('mapbox-gl')}
                    style={{width: '100%', height: '100%'}}
                    initialViewState={{
                        latitude: this.getCenter(this.props.spots.concat([this.props.location])).lat,
                        longitude: this.getCenter(this.props.spots.concat([this.props.location])).lng,
                        zoom: this.getZoom(this.props.spots.concat([this.props.location]))
                    }}
                    dragPan={true}
                    mapStyle={"mapbox://styles/mapbox/streets-v12"}
                    onMove={(props) => {
                        const map = props.target;
                        const bounds = map.getBounds();
                        const ne = bounds.getNorthEast();
                        const sw = bounds.getSouthWest();

                        this.setState({
                            currentMapBounds: {
                                lat_min: sw.lat, lat_max: ne.lat, lng_min: sw.lng, lng_max: ne.lng
                            }
                        });

                        const $maxLatitude = ne.lat;
                        const $minLatitude = sw.lat;
                        const $maxLongitude = ne.lng;
                        const $minLongitude = sw.lng;
                        const $earthRadius = 6371; // km
                        const $dLat = this.deg2rad($maxLatitude - $minLatitude);
                        const $dLon = this.deg2rad($maxLongitude - $minLongitude);
                        const $a = Math.sin($dLat / 2) * Math.sin($dLat / 2) + Math.cos(this.deg2rad($minLatitude)) * Math.cos(this.deg2rad($maxLatitude)) * Math.sin($dLon / 2) * Math.sin($dLon / 2);
                        const $c = 2 * Math.atan2(Math.sqrt($a), Math.sqrt(1 - $a));
                        const areaInSquareKilometers = $earthRadius * $c * $earthRadius * $c;
                        const searchThisAreaButton = document.querySelector('.search-this-area');

                        if (areaInSquareKilometers > 15000) {
                            // disable searchThisAreaButton
                            searchThisAreaButton.setAttribute('disabled', 'disabled');
                            // set text
                            searchThisAreaButton.innerText = "Zoom in to search this area";
                        } else {
                            // enable searchThisAreaButton
                            searchThisAreaButton.removeAttribute('disabled');
                            // set text
                            searchThisAreaButton.innerText = "Search this area";
                        }

                        // remove hidden class from the search-this-area button
                        if (!this.state.firstRender) {
                            searchThisAreaButton.classList.remove('hidden');
                        }

                        this.setState({firstRender: false});
                    }}
                    onLoad={(props) => {
                        this.setState({map: props.target});

                        const map = props.target;
                        const boundingBox = this.getBoundingBox(this.props.spots.concat([this.props.location]), true, 0.1);
                        map.fitBounds(boundingBox);
                    }}
                >

                    <Marker
                        key={"My Location"}
                        latitude={Number(this.props.location.latitude)}
                        longitude={Number(this.props.location.longitude)}
                        color="#fa6d6d"
                        onClick={e => {
                            this.setState({
                                selectedSpot: this.props.location,
                            })
                        }}
                    />

                    {this.props.spots.map(spot => {
                        return (<Marker
                            key={spot.surfline_id}
                            latitude={Number(spot.latitude)}
                            longitude={Number(spot.longitude)}
                            color={spot.id === 0 ? "#fa6d6d" : null}
                            onClick={e => {
                                this.setState({
                                    selectedSpot: spot,
                                })
                            }}
                        />)
                    })}

                    {this.state.selectedSpot !== null && (<Popup
                        key={this.state.selectedSpot.surfline_id ?? 0}
                        closeOnMove={false}
                        closeOnClick={false}
                        latitude={Number(this.state.selectedSpot.latitude)}
                        longitude={Number(this.state.selectedSpot.longitude)}
                        onClose={this.onPopupClose}
                        onOpen={(e) => {
                            this.setState(
                                {donationLayerCount: this.state.donationLayerCount + 1}
                            );
                            this.onPopupOpen(e)
                        }}
                        maxWidth={"75%"}
                    >
                        <SpotCard spot={this.state.selectedSpot} location={this.props.location}
                                  showDonationLayer={this.state.donationLayerCount % 3 === 0}/>
                    </Popup>)}

                </Map>
                <button className={"btn btn-primary change-filters"}
                        onClick={this.onFilterChangeButtonClick}>Change
                    filters
                </button>
                <button className={"btn btn-light spots-count"}
                        onClick={() => this.fitMapBoundsToSpots()}>
                    {this.props.spots.length} spots found
                </button>
                <button className={"btn btn-primary search-this-area hidden"}
                        onClick={this.onSearchThisAreaButtonClick}>Search
                    this area
                </button>
            </div>
        </div>);
    }
}

export default SpotsMap;
