import React from "react";

const Privacy = () => {
    return <div className="app">
        <div id={"form"} className={"layer full-screen"} style={{overflowY: "scroll", display: "block"}}>
            <div className="container">
                <div className="row">
                    <div className="col-8 col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 ms-auto me-auto">
                        <div id={"search-form"} className="search-form row g-3">
                            <div className="col-12">
                                <h1>
                                    Privacy Policy
                                </h1>
                                <p>
                                    Effective Date: 29.01.25
                                    <br/><br/>
                                    Welcome to Kook Surf Finder ("we," "our," or "us"). We are committed to protecting
                                    your privacy and ensuring that your personal data is handled securely and
                                    transparently. This Privacy Policy outlines how we collect, use, and protect your
                                    information when you use our website in compliance with the General Data Protection
                                    Regulation (GDPR) and other applicable data protection laws.
                                    <br/><br/>
                                    1. Data Controller
                                    <br/><br/>
                                    The data controller responsible for your personal data is:
                                    <br/><br/>
                                    <strong>Kook Surf Finder</strong><br/>
                                    Michael Böhm<br/>
                                    Königsbrücker Str. 91<br/>
                                    01099 Dresden<br/>
                                    Germany<br/>
                                    +4915788414388<br/>
                                    <a href={`mailto:info@kooksurfffinder.com`}>info@kooksurfffinder.com</a>
                                    <br/><br/>
                                    2. Personal Data We Collect
                                    <br/><br/>
                                    We only collect and process location data when you use our service to find surf
                                    spots near you. No other personal data such as contact information, usage data, or
                                    tracking cookies are collected.
                                    <br/><br/>
                                    3. How We Use Your Data
                                    <br/><br/>
                                    We use your location data solely to provide relevant surf spot results. Your
                                    location is not stored or shared with third parties beyond the service required to
                                    provide maps and surf spot information.
                                    <br/><br/>
                                    4. Third-Party Services
                                    <br/><br/>
                                    We use Mapbox to display maps and relevant surf spot information. Mapbox may collect
                                    certain data as part of their service. For more details on how Mapbox handles your
                                    data, please refer to their privacy policy: <a target={"_blank"} href={`https://www.mapbox.com/legal/privacy`} rel="noreferrer">https://www.mapbox.com/legal/privacy</a>
                                    <br/><br/>
                                    5. Legal Basis for Processing
                                    <br/><br/>
                                    Under the GDPR, we process your location data based on:
                                    <br/><br/>
                                    Consent: When you grant location access in your browser or device.
                                    <br/><br/>
                                    6. How We Share Your Data
                                    <br/><br/>
                                    We do not sell or share your personal data. However, location data is processed
                                    through Mapbox solely for the purpose of providing mapping functionality.
                                    <br/><br/>
                                    7. Data Retention
                                    <br/><br/>
                                    We do not store your location data. Once your session ends, no location data is
                                    retained.
                                    <br/><br/>
                                    8. Your Rights Under GDPR
                                    <br/><br/>
                                    As an EU resident, you have the following rights regarding your personal data:
                                    <br/><br/>
                                    Withdraw Consent: You can disable location access at any time via your browser or
                                    device settings.
                                    <br/><br/>
                                    Access & Rectification: Since we do not store location data, no further action is
                                    required.
                                    <br/><br/>
                                    9. Security Measures
                                    <br/><br/>
                                    We take necessary precautions to ensure your data is processed securely and only
                                    used for the intended purpose.
                                    <br/><br/>
                                    10. Changes to This Privacy Policy
                                    <br/><br/>
                                    We may update this policy from time to time. Changes will be posted on this page
                                    with an updated effective date.
                                    <br/><br/>
                                    11. Contact Information
                                    <br/><br/>
                                    If you have any questions about this Privacy Policy, please contact us at: <a
                                    href={`mailto:info@kooksurfffinder.com`}>info@kooksurfffinder.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id={"bg-image"} className={"layer full-screen"}/>
    </div>
};

export default Privacy;
