import React from "react";

const Imprint = () => {
    return <div className="app">
        <div id={"form"} className={"layer full-screen"}>
            <div className="container">
                <div className="row">
                    <div className="col-8 col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 ms-auto me-auto">
                        <div id={"search-form"} className="search-form row g-3">
                            <div className="col-12">
                                <h1>
                                    Imprint
                                </h1>
                                <p>
                                    Information in accordance with Section 5 TMG<br/><br/>

                                    <strong>Kook Surf Finder</strong><br/>
                                    Michael Böhm<br/>
                                    Königsbrücker Str. 91<br/>
                                    01099 Dresden<br/>
                                    Germany<br/>
                                    +4915788414388<br/>
                                    <a href={`mailto:info@kooksurfffinder.com`}>info@kooksurfffinder.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id={"bg-image"} className={"layer full-screen"}/>
    </div>
};

export default Imprint;
