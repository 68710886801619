import React, {Component} from 'react';
import ForecastsTable from "../ForecastsTable/ForecastsTable";

export class SpotCard extends Component {

    getForecastTables = function (forecasts) {
        const forecastsByDay = this.getForecastsByDay(forecasts);

        return Object.keys(forecastsByDay).map(date => {
            return <ForecastsTable key={date} forecasts={forecastsByDay[date]}/>
        });
    }

    getForecastsByDay = function (forecasts) {
        const forecastsByDay = {};
        forecasts.forEach(forecast => {
            const date = new Date(forecast.datetime.substring(0, 10));
            if (!forecastsByDay.hasOwnProperty(date)) {
                forecastsByDay[date] = [];
            }
            forecastsByDay[date].push(forecast);
        });
        return forecastsByDay;
    }


    render() {
        return (<div className={"card spot-card inner " + (this.props.spot.id === undefined ? 'my-location' : '')}>
                <div className="card-body">
                    <h6 className="card-title">{this.props.spot.name}</h6>

                    {this.props.spot.id !== undefined ?
                        <div>
                            <div className="mb-2 d-flex justify-content-left">
                                <div className={"distance"}>
                                    <i className="fa-solid fa-location-dot"></i>&nbsp;
                                    {Math.round(this.props.spot.distance)}&nbsp;&nbsp;km away
                                </div>
                                <div className={"route"}>
                                    <a target={"_blank"} rel={"noopener noreferrer"}
                                       href={`https://www.google.com/maps/dir/?api=1&origin=${this.props.location.latitude},${this.props.location.longitude}&destination=${this.props.spot.latitude},${this.props.spot.longitude}&travelmode=driving`}>
                                        <i className="fa-solid fa-diamond-turn-right"></i>
                                    </a>
                                </div>
                                {false && this.props.spot.surfline_url != null ?
                                    <div className={"external"}>
                                        <a target={"_blank"} rel={"noopener noreferrer"}
                                           href={this.props.spot.surfline_url}>
                                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                        </a>
                                    </div>
                                    : ''}
                            </div>

                            {

                                this.props.spot.hasOwnProperty('forecasts') && this.props.spot.forecasts.length ?
                                    this.getForecastTables(this.props.spot.forecasts) : 'No forecasts available for this spot.'
                            }

                            {
                                this.props.showDonationLayer ?
                                    <div className={"row"}>
                                        <div id="donation-button-notice" className={"col-6"}>
                                            Do you like KookSurfFinder? <br/>Consider helping us cover the costs for
                                            this site so we can keep it
                                            free 🩵
                                        </div>
                                        <div id={"donation-button-wrapper"} className={"col-6"}>
                                            <form action="https://www.paypal.com/donate" method="post" target="_blank">
                                                <input type="hidden" name="hosted_button_id" value="J84BGRX7HXVL6"/>
                                                <input type="image"
                                                       src="/button-donate.png"
                                                       border="0"
                                                       name="submit"
                                                       alt="Donate with PayPal button"/>
                                                <img alt="" border="0"
                                                     src="https://www.paypal.com/en_DE/i/scr/pixel.gif"
                                                     width="1"
                                                     height="1"/>
                                            </form>
                                        </div>
                                    </div> : ''
                            }
                        </div>
                        : ''}
                </div>
            </div>
        );
    }
}


export default SpotCard;
